import classNames from 'classnames'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { useFormatPrice } from '~/hooks'

import { PriceProps } from './Price.types'

export const Price = ({
  regularPrice,
  finalPrice,
  tag = TypographyTag.span,
  showFrom = false,
  showSave = true,
  dataTestId,
  variant = TypographyVariant.BodyRegular,
  offerExpiresAt,
  displaySaveOnNewLine = false,
  className,
  finalPriceClassName,
  regularPriceClassName,
  savePriceClassName,
  offerClassName,
}: PriceProps) => {
  const { formatPrice, formatSave, formatDate } = useFormatPrice()

  const isRegularPriceSameAsFinalPrice =
    regularPrice?.centAmount <= finalPrice?.centAmount

  const isShowSavedPrice =
    showSave && !!regularPrice?.centAmount && !!finalPrice?.centAmount

  return (
    <Typography
      tag={tag}
      variant={variant}
      data-test-id={dataTestId}
      className={className}>
      {showFrom && <span className='mr-1'>From</span>}

      {finalPrice && (
        <span
          className={classNames(
            'inline-block font-bold mr-2',
            finalPriceClassName || 'text-2xl md:text-3xl !leading-8'
          )}>
          {formatPrice(finalPrice)}
        </span>
      )}

      {regularPrice && !isRegularPriceSameAsFinalPrice && (
        <span
          className={classNames(
            'line-through font-normal mr-2 text-primary-muted inline-block',
            regularPriceClassName || 'text-lg leading-6'
          )}>
          {formatPrice(regularPrice)}
        </span>
      )}

      <div
        className={classNames('font-bold text-ecommerce-sale', offerClassName, {
          'block mt-1': displaySaveOnNewLine,
        })}>
        <span
          className={classNames(
            'inline-block mr-2',
            savePriceClassName || 'text-2xl md:text-3xl !leading-8'
          )}>
          {isShowSavedPrice && !isRegularPriceSameAsFinalPrice
            ? `Save ${formatSave(regularPrice, finalPrice, false)}`
            : null}
        </span>
        <span className={classNames('inline-block text-sm leading-4')}>
          {offerExpiresAt && `Offer expires ${formatDate(offerExpiresAt)}`}
        </span>
      </div>
    </Typography>
  )
}
