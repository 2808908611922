import { useContext, useRef } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { GetUpsellProductsResponse, UpdateCartRequest } from 'shared-types'
import { CartUpsellProductSliderProps } from '~/components/CartUpsellProductSlidier'
import { request } from '~/customClients/request'

import { useProductDataLayer } from '~/lib/gtm/hooks'
import { CartDrawerProviderContext } from '~/providers/CartDrawerProvider'
import { Client } from '../../customClients/client'
import { useSession } from '../useSession'

/**
 * Fetch cart data.
 */

export function useCart(isFetchUpsellProducts = false) {
  const { session } = useSession()
  const queryClient = useQueryClient()
  const { dispatchAddToCartDataLayerEvent } = useProductDataLayer()

  const {
    data: cart,
    refetch: getCart,
    isError: isErrorCart,
    isLoading: isLoadingCart,
  } = useQuery(
    ['cart', session],
    async () => {
      return Client.cart.getCart(session)
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!session,
    }
  )

  const {
    data: upsellProducts,
    refetch: getUpsellProducts,
    isError: isErrorUpsellProducts,
    isLoading: isLoadingUpsellProducts,
  } = useQuery(
    ['upsell-products', session],
    async () => {
      const response = await request<GetUpsellProductsResponse>(
        { method: 'GET', url: '/bff/cart/upsell' },
        session
      )
      if (response.getErrorSafe()) {
        return null
      }
      const data = response.getValueSafe()
      if (data?.upsellProducts?.length > 0) {
        return {
          title: data.title || '',
          products: data.upsellProducts,
        } as CartUpsellProductSliderProps
      }
      return null
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: isFetchUpsellProducts,
    }
  )

  const updateCart = useMutation(
    async (payload: UpdateCartRequest[]) => {
      return Client.cart.updateCart(payload, session)
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.setQueryData(['cart', session], data)
          dispatchAddToCartDataLayerEvent(data)
        }
      },
    }
  )

  const addedProduct = useRef<UpdateCartRequest[]>()
  const addToCart = useMutation(
    async (payload: UpdateCartRequest[]) => {
      addedProduct.current = payload
      return Client.cart.addToCart(payload, session)
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['cart', session], data)
        const lineItems = []

        addedProduct.current?.forEach((product) => {
          data.lineItems?.find((item) => {
            if (item.variant.sku === product.sku) {
              lineItems.push({
                ...item,
                quantity: product.quantity,
                totalQuantity: item.quantity,
              })
            }
          })
        })

        dispatchAddToCartDataLayerEvent({ ...data, lineItems })
      },
    }
  )

  const reorderToCart = useMutation(
    async (orderNumber: string) => {
      return Client.order.reOrder(orderNumber, session)
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          ['cart', session],
          data.getValueSafe().cartSummary
        )
        dispatchAddToCartDataLayerEvent(data.getValueSafe().cartSummary)
      },
    }
  )

  return {
    cart,
    getCart,
    addToCart,
    isErrorCart,
    isLoadingCart,
    upsellProducts,
    getUpsellProducts,
    isErrorUpsellProducts,
    isLoadingUpsellProducts,
    updateCart,
    reorderToCart,
  }
}

export const useCartDrawer = () => {
  return useContext(CartDrawerProviderContext)
}
