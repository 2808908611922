import { useCallback } from 'react'
import { BaseMoney } from 'shared-types/src/commercetools'
import { usePage } from '../usePage'

export type UseFormatPrice = {
  formatDate: (timestamp: string) => string
  formatPrice: (price?: BaseMoney) => string
  formatPriceSymbol: (price?: BaseMoney) => string
  formatSave: (
    regularPrice: BaseMoney,
    finalPrice: BaseMoney,
    isRounded?: boolean
  ) => string
}

const createFormatter = (
  currency: string,
  locale?: string,
  fraction?: number
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: fraction,
  })
}

const defaultFraction = 2
const dollarsToCentsDenominator = 100

const isValid = (price: BaseMoney) => {
  return price?.currencyCode && price?.centAmount
}

export function useFormatPrice(): UseFormatPrice {
  const { head } = usePage() || {}
  const locale = head?.locale

  const formatter = useCallback(
    (currency: string, fraction: number = defaultFraction) => {
      return createFormatter(currency, locale, fraction)
    },
    [locale]
  )

  const formatDate = useCallback(
    (date: string) => {
      return new Date(date).toLocaleDateString(locale)
    },
    [locale]
  )

  const formatPrice = (price: BaseMoney) => {
    if (!isValid(price)) {
      return ''
    }
    return formatter(price.currencyCode).format(
      price.centAmount / dollarsToCentsDenominator
    )
  }

  const formatPriceSymbol = (price: BaseMoney) => {
    const formattedPrice = formatPrice({ ...price, centAmount: 0 })
    return formattedPrice.slice(0, Number('-4'))
  }

  const formatSave = (
    regularPrice: BaseMoney,
    finalPrice: BaseMoney,
    isRounded = false
  ) => {
    if (!isValid(regularPrice) || !isValid(finalPrice)) {
      return ''
    }

    return formatter(
      regularPrice?.currencyCode,
      isRounded ? 0 : defaultFraction
    ).format(
      (regularPrice.centAmount - finalPrice.centAmount) /
        dollarsToCentsDenominator
    )
  }

  return {
    formatPrice,
    formatSave,
    formatDate,
    formatPriceSymbol,
  }
}
